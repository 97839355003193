import React, { FC, useState } from 'react';
import { useTranslation } from 'next-i18next';

import { useRequestBalance } from '@lib/balance/useRequestBalance';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { SupportedPaymentTypes } from '@lib/payment/types';

import { AlertDialog } from '../../../ui/dialogs/AlertDialog';
import { PaymentSelect } from './payment-select/PaymentSelect';
import { PaymentSelectOption } from './types';
import { getEnv } from '@lib/env/getEnv';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { useAuth } from '@lib/auth/AuthContext';
import { api } from '@api/index';
import { formatGenericError } from '@lib/fetch/errors';
import { usePurchaseInfo } from '@templates/purchase/common/PurchaseInfoContext';
import { usePaymentOptions } from './usePaymentOptions';
import { OrderDetails } from '@api/ocb-digital/order/types';

interface Props {
  onSubmit: (selectedType: SupportedPaymentTypes) => void;
  onCancel: () => void;
  cost: number;
  title?: string;
  description?: string;
  cancelText?: string;
  submitText?: string;
  isOpen?: boolean;
  isLoading?: boolean;
  selectedOrder?: OrderDetails;
}

export const PaymentDialog: FC<Props> = ({
  title,
  isOpen = false,
  description,
  isLoading,
  onCancel,
  onSubmit,
  cancelText,
  submitText,
  cost,
  selectedOrder,
}) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { data: tenantPaymentCapabilities } = useApiQuery(
    () => getTenantPaymentOptions(),
    { queryKey: [CACHE_KEYS.tenantPaymentCapabilities] },
  );

  const isBalanceEnabled = !!tenantPaymentCapabilities?.find(
    (tenant) => tenant.paymentType === SupportedPaymentTypes.PrepaidBalance,
  );

  const order = usePurchaseInfo().order ?? selectedOrder;
  const {
    data,
    isLoading: isBalanceLoading,
    isError,
  } = useRequestBalance(CACHE_KEYS.accountBalance, isOpen && isBalanceEnabled);
  const isInsufficientBalance =
    (data && cost > data?.cashBalance?.outstandingAmount) || isError;

  const env = getEnv();
  const { tenant } = useTenantProps();
  const payPalPublicKey =
    tenant.config.setup.paypalPublicKey[env.client.appEnv];
  const tenantId = tenant.config.setup.id[env.client.appEnv];

  const { filterPaymentOptions } = usePaymentOptions({
    order,
    tenantPaymentCapabilities,
  });

  const options: PaymentSelectOption[] = [
    {
      id: SupportedPaymentTypes.Online,
      label: t('common:payment-select-options.ONLINE'),
    },
    {
      id: SupportedPaymentTypes.PrepaidBalance,
      label: t('common:payment-select-options.PREPAID_BALANCE'),
      isDisabled: isInsufficientBalance,
      isLoading: isBalanceLoading,
    },
    {
      id: SupportedPaymentTypes.PayPal,
      label: t('common:payment-select-options.PAYPAL'),
      isDisabled: !payPalPublicKey,
    },
    {
      id: SupportedPaymentTypes.TLync,
      label: t('common:payment-select-options.TLYNC'),
    },
  ].filter(filterPaymentOptions);
  const initialValue = options?.[0]?.id ?? '';
  const [selectedPaymentType, selectPaymentType] =
    useState<SupportedPaymentTypes>(initialValue);

  return (
    <AlertDialog
      onSubmit={() => onSubmit(selectedPaymentType)}
      onCancel={onCancel}
      title={title ?? t('common:dialogs.payment.title')}
      description={description ?? t('common:dialogs.payment.description')}
      cancelText={cancelText ?? t('common:cancel')}
      submitText={submitText ?? t('common:proceed')}
      open={isOpen}
      isLoading={isLoading || isBalanceLoading}
      fullWidth
      maxWidth="xs"
      content={
        <PaymentSelect
          options={options}
          initialValue={initialValue}
          onSelect={selectPaymentType}
        />
      }
      data-testid="payment-dialog"
    />
  );

  async function getTenantPaymentOptions() {
    try {
      if (!tenantId) {
        throw new Error(t('common:errors.unexpected'));
      }
      return isAuthenticated
        ? await api.ocbDigital.tenant.getTenantPaymentCapabilities(tenant)
        : await api.ocbDigital.tenant.getTenantPaymentCapabilitiesForUnauthenticatedUser(
            tenantId,
            tenant,
          );
    } catch (error) {
      formatGenericError(error);
    }
  }
};
