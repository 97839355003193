import { api } from '@api/index';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { useTranslation } from 'next-i18next';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useModal } from '@lib/useModal';
import { PaymentDialog } from '@templates/common/payment-dialog/PaymentDialog';
import {
  AvailablePaymentModals,
  SupportedPaymentTypes,
} from '@lib/payment/types';
import { usePaymentChecker } from '@lib/payment/usePaymentChecker';
import { toast } from 'react-toastify';
import { formatGenericError } from '@lib/fetch/errors';
import { retainQueryParams, withQueryParams } from '@lib/route/queryParams';
import { Tenant } from '@lib/tenants/types';
import { useRouter } from 'next/router';
import { PurchaseQueryParams } from '@templates/purchase/common/types';
import { PayPalPaymentModal } from '@templates/purchase/payment-modals/paypal-payment-modal/PayPalPaymentModal';
import { RyftPaymentModal } from '@templates/purchase/payment-modals/ryft-payment-modal/RyftPaymentModal';
import { PaymentStepContent } from '@templates/purchase/common/payment-step/PaymentStepContent';
import { ContentModal } from '@ui/content-modal/ContentModal';
import { StripePaymentModal } from '@templates/purchase/payment-modals/stripe-payment-modal/StripePaymentModal';
import { OrderDetails } from '@api/ocb-digital/order/types';
import { SYMBOLS } from '@lib/placeholders/constants';

interface Props {
  refetchPayments(): void;
}

export interface PaymentRenewalModalRef {
  openModal: (order: OrderDetails) => void;
}

export const PaymentRenewalModal = forwardRef(function PaymentRenewalModal(
  { refetchPayments }: Props,
  ref,
) {
  const { tenant } = useTenantProps();
  const { t } = useTranslation('home');
  const router = useRouter();
  const { checkPayment, orderId = '' } = router.query as PurchaseQueryParams;
  const { Modal, isOpen, showModal, hideModal } = useModal();
  const [selectedOrder, setSelectedOrder] = useState<OrderDetails | undefined>(
    undefined,
  );
  const [isHandlingPayment, setIsHandlingPayment] = useState(false);
  const [isRyftModalOpen, setIsRyftModalOpen] = useState(false);
  const [isStripeModalOpen, setIsStripeModalOpen] = useState(false);
  const [isPayPalModalOpen, setIsPayPalModalOpen] = useState(false);
  const {
    fetchOrder: checkPaymentStatus,
    isLoading: isCheckingPayment,
    data: paymentData,
    isError: isPaymentError,
  } = usePaymentChecker({
    onRedirect: handleRedirect,
    onOpenPaymentModal: onOpenPaymentModal,
    onPaymentSuccess: onPaymentFinish,
    onPaymentError: onPaymentFinish,
    customOrderApi: api.ocbDigital.order.getOrderManagementPaymentStatus,
  });
  const isLoadingPayment =
    (isCheckingPayment || isHandlingPayment) &&
    !isRyftModalOpen &&
    !isPayPalModalOpen;

  useImperativeHandle(
    ref,
    () => ({
      openModal(order: OrderDetails) {
        setSelectedOrder(order);
        showModal();
      },
    }),
    [showModal],
  );

  const [callbackUrlWithParams, setCallbackUrlWithParams] = useState('');

  useEffect(() => {
    const currentUrl = window?.location?.href ?? '';
    setCallbackUrlWithParams(
      withQueryParams(currentUrl, {
        checkPayment: true,
        orderId,
      }),
    );
  }, [orderId]);

  useEffect(() => {
    if (checkPayment && orderId) {
      checkPaymentStatus({ orderId });
    }
  }, [checkPayment, checkPaymentStatus, orderId]);

  return (
    <>
      <Modal>
        <PaymentDialog
          cost={selectedOrder?.orderPayment?.amount?.amount ?? 0}
          isLoading={isHandlingPayment}
          isOpen={isOpen}
          onSubmit={handlePayment}
          onCancel={() => hideModal()}
          selectedOrder={selectedOrder}
        />
      </Modal>
      <PayPalPaymentModal
        open={isPayPalModalOpen}
        onClose={onPayPalPaymentCancel}
        orderId={selectedOrder?.orderId}
        onPaymentApprove={onPaymentSubmitted}
      />
      <StripePaymentModal
        open={isStripeModalOpen}
        clientSessionId={paymentData?.orderPayment?.clientSessionId}
        onClose={() => setIsStripeModalOpen(false)}
        totalUpfront={selectedOrder?.orderPayment?.amount?.amount ?? 0}
        currency={
          selectedOrder?.orderPayment.amount?.currency ?? SYMBOLS.emptyText
        }
        callbackUrl={callbackUrlWithParams}
      />
      <RyftPaymentModal
        open={isRyftModalOpen}
        clientSessionId={paymentData?.orderPayment?.clientSessionId}
        onClose={onRyftPaymentCancel}
        totalUpfront={selectedOrder?.orderPayment?.amount?.amount ?? 0}
        onCheckPaymentStatus={onPaymentSubmitted}
        paymentType={selectedOrder?.hasRecurringFee ? 'Unscheduled' : undefined}
        currency={
          selectedOrder?.orderPayment.amount?.currency ?? SYMBOLS.emptyText
        }
      />
      {isLoadingPayment && (
        <ContentModal isOpen={true} onCancel={() => {}}>
          <PaymentStepContent
            isError={isPaymentError}
            loaderLabel={t('auth.balance.loadingTitle')}
          />
        </ContentModal>
      )}
    </>
  );

  async function onPaymentSubmitted() {
    router.replace(
      withQueryParams(router.pathname, {
        ...router.query,
        orderId: selectedOrder?.orderId,
        checkPayment: true,
        paymentSubmitted: true,
      }),
      undefined,
      {
        shallow: true,
      },
    );
  }

  function onOpenPaymentModal(availablePaymentModal: AvailablePaymentModals) {
    if (availablePaymentModal === 'PAYPAL') {
      setIsPayPalModalOpen(true);
    } else if (availablePaymentModal === 'RYFT') {
      setIsRyftModalOpen(true);
    } else if (availablePaymentModal === 'STRIPE') {
      setIsStripeModalOpen(true);
    }
  }

  async function handlePayment(selectedType?: SupportedPaymentTypes) {
    try {
      setIsHandlingPayment(true);
      const orderId = selectedOrder?.orderId;

      if (!orderId) {
        throw new Error(t('common:errors.unexpected'));
      }

      await updatePaymentData(tenant, orderId, selectedType);
      hideModal();
      checkPaymentStatus({ orderId });
    } catch (error) {
      toast.error(formatGenericError(error));
      throw error;
    } finally {
      setIsHandlingPayment(false);
    }
  }

  function onPayPalPaymentCancel(): void {
    setIsPayPalModalOpen(false);
    refetchPayments();
  }

  function onRyftPaymentCancel(): void {
    setIsRyftModalOpen(false);
    refetchPayments();
  }

  function onPaymentFinish(): void {
    router.replace(router.pathname, undefined, { shallow: true });
    refetchPayments();
  }

  function handleRedirect(redirectUrl: string): void {
    router.push(redirectUrl);
  }
});

async function updatePaymentData(
  tenant: Tenant,
  orderId: string,
  selectedType: SupportedPaymentTypes = SupportedPaymentTypes.Online,
) {
  const callbackUrl = withQueryParams(window.location.href, {
    checkPayment: true,
    orderId,
  });
  const queryParamsAllowedForCallback = [
    'orderId',
    'sourceId',
    'step',
    'checkPayment',
  ];
  const sanitizedCallbackUrl = retainQueryParams(
    callbackUrl,
    queryParamsAllowedForCallback,
  );

  return await api.ocbDigital.order.updatePaymentData(tenant, orderId, {
    callbackUrl: sanitizedCallbackUrl,
    paymentType: selectedType,
  });
}
