import { UseMutateAsyncFunction } from '@tanstack/react-query';

import {
  FailureDetails,
  GetOrderRo as GetAuthOrderRo,
  OrderManagementPaymentStatus,
  OrderManagementPaymentStatusDto,
  Payment,
  SelectedProduct,
} from '@api/ocb-digital/order/types';
import { GetOrderRo as GetAuthlessOrderRo } from '@api/ocb-digital/order-authless/types';
import { GetCustomerRo } from '@api/ocb-digital/customer/types';

export interface Order {
  id: string;
  omOrderId?: string;
  orderStatus?: OrderStatus;
  orderPayment?: Payment;
  offer?: OrderOffer; //DEPRECATED will be removed
  offers?: OrderOffer[];
  terminatingSubscription?: OrderSubscriptionView;
  failureDetails?: FailureDetails;
  upfrontPayment?: Price;
  totalPayment?: Price;
  refund?: Price;
  customer?: GetCustomerRo;
  hasRecurringFee?: boolean;
}

export interface OrderSubscriptionView {
  subscriptionId?: string;
  nextBillCycleDate?: string;
}

interface Price {
  amount: number;
  amountNoVat: number;
  vat: number;
  currency: string;
  chargeCode: string;
}

export enum PaymentProviderType {
  Paystack = 'PAYSTACK',
  Ryft = 'RYFT',
  TLync = 'TLYNC',
  Stripe = 'STRIPE',
}

interface OrderOffer {
  offerId: string;
  selectedProducts: SelectedProduct[];
}

export interface PaymentMutationParams {
  orderId: string;
  isSkipping?: boolean;
  requestId?: string;
}

export type AvailablePaymentModals = 'RYFT' | 'PAYPAL' | 'STRIPE';

export interface PaymentCheckerParams {
  onPaymentSuccess?: () => void;
  onPaymentError?: (details?: FailureDetails) => void;
  onPaymentSkip?: () => void;
  onRedirect?: (redirectUrl: string, orderId?: string) => void;
  onOpenPaymentModal?: (paymentModal: AvailablePaymentModals) => void;
  customOrderApi?: (
    params: OrderManagementPaymentStatusDto,
  ) => Promise<OrderManagementPaymentStatus>;
}

export interface PaymentCheckerReturnType {
  isError: boolean;
  isLoading: boolean;
  data:
    | GetAuthOrderRo
    | GetAuthlessOrderRo
    | OrderManagementPaymentStatus
    | undefined;
  fetchOrder: OrderFetch;
}

export type OrderFetch = UseMutateAsyncFunction<
  | GetAuthOrderRo
  | GetAuthlessOrderRo
  | OrderManagementPaymentStatus
  | undefined,
  unknown,
  PaymentMutationParams,
  unknown
>;

export enum SupportedPaymentTypes {
  Online = 'ONLINE',
  PrepaidBalance = 'PREPAID_BALANCE',
  DealerBalance = 'DEALER_BALANCE',
  PayPal = 'PAYPAL',
  TLync = 'TLYNC',
}

export type OrderStatus =
  | 'FINISHED'
  | 'FAILED'
  | 'INITIALIZED'
  | 'PAYMENT'
  | 'SUBMITTED'
  | 'PAYMENT_FAILED'
  | null;

export type PaymentStatus = 'FINISHED' | 'INITIALIZED' | 'FAILED';
