import { useCallback } from 'react';
import { PaymentSelectOption } from './types'; // Adjust the import paths as necessary
import { Order } from '@lib/payment/types';
import { TenantPaymentCapabilities } from '@api/ocb-digital/tenant/types';
import { OrderDetails } from '@api/ocb-digital/order/types';

interface UsePaymentOptionsParams {
  order?: Order | OrderDetails | null;
  tenantPaymentCapabilities?: TenantPaymentCapabilities[];
}

export const usePaymentOptions = ({
  order,
  tenantPaymentCapabilities,
}: UsePaymentOptionsParams) => {
  const checkIfRecurringPaymentAllowed = useCallback(
    (recurring: boolean) => recurring || recurring === order?.hasRecurringFee,
    [order?.hasRecurringFee],
  );

  const filterPaymentOptions = useCallback(
    (option: PaymentSelectOption) => {
      return tenantPaymentCapabilities?.some(
        (capability) =>
          capability?.paymentType === option?.id &&
          capability.oneOff &&
          checkIfRecurringPaymentAllowed(capability.recurring),
      );
    },
    [checkIfRecurringPaymentAllowed, tenantPaymentCapabilities],
  );

  return { filterPaymentOptions };
};
